@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(100%);
    opacity:0
  }

  100% {
    transform: translateX(0);
    opacity: 100;
  }
}

@layer base {
  h1,
  h2,
  h3,
  h4 {
    color: #0e1227;
  }
  h1 {
    font-family: "Inter-SemiBold";
    font-size: 24px;
    line-height: 38px;
  }
  h2 {
    font-family: "Inter-SemiBold";
    font-size: 20px;
    line-height: 30px;
  }
  h3 {
    font-family: "Inter-Medium";
    font-size: 16px;
    line-height: 28px;
  }
  h4 {
    font-family: "Inter-Medium";
    font-size: 12px;
  }
  p {
    font-family: "Inter-Regular";
    font-size: 14px;
    line-height: 24px;
  }
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter"),
    url("../public/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter"),
    url("../public/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter"),
    url("../public/fonts/Inter-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0px;
  font-family: "Inter-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  fill: currentColor;
  scroll-behavior: smooth;
  box-sizing: border-box;
  background-color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h1 {
  font-family: "Inter-SemiBold";
  font-size: 24px;
  line-height: 48px;
}

.h1Large{
  font-size:30px;
  font-family: "Inter-SemiBold";
  line-height: 40px;
}

.h2 {
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 24px;
}

.h3 {
  font-family: "Inter-Medium";
  font-size: 18px;
  line-height: 48px;
}

.h3Bold {
  font-family: "Inter-SemiBold";
  font-size: 18px;
  line-height: 48px;
}

.h4 {
  font-family: "Inter-Medium";
  font-size: 16px;
  line-height: 48px;
}

.h4BoldRegular {
  font-family: "Inter-Medium";
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.h5 {
  font-family: "Inter-Medium";
  font-size: 12px;
  line-height: 24px;
}

.h5Italic {
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
}

.h5BoldRegular {
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.h5Bold {
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  font-weight: 600;
}

.h6 {
  font-family: "Inter-SemiBold";
  font-size: 10px;
  line-height: 24px;
}

.text1 {
  font-family: "Inter-Regular";
  font-size: 20px;
  line-height: 34px;
}

.text1Medium {
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 30px;
}

.text2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  line-height: 24px;
}

.text2Bold {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  line-height: 24px;
}

.text3 {
  font-family: "Inter-Regular";
  font-size: 12px;
  line-height: 16px;
}

.text3Bold {
  font-family: "Inter-SemiBold";
  font-size: 12px;
  line-height: 16px;
}

.text4 {
  font-family: "Inter-Regular";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.text5 {
  font-family: "Inter-Regular";
  font-size: 12px;
  line-height: 24px;
}
.text-sm-SemiBold {
  font-family: "Inter-Regular";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.text-sm-Regular {
  font-family: "Inter-Regular";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-sm-Medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.text-lg-SemiBold {
  font-family: "Inter-Regular";
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
.text-3xl-Bold {
  font-family: "Inter-Regular";
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}
.text-base-SemiBold {
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.caption1 {
  font-family: "Inter-Regular";
  font-size: 10px;
  line-height: 24px;
}
.poppins-Caption {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.border {
  border: 1px solid #e5e7eb;
}

.border-right {
  border-right: 1px solid #e5e7eb;
}

.border-left {
  border-left: 1px solid #e5e7eb;
}

.border-top {
  border-top: 1px solid #e5e7eb;
}

.border-bottom {
  border-bottom: 1px solid #e5e7eb;
}

.shadow {
  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
}



.checkbox {
  accent-color: #0a8bfe;
  height: 16px; /* not needed */
  width: 16px; /* not needed */
}

.checkbox-small {
  height: 12px;
  width: 12px;
}

.link {
  cursor: pointer;
}

/*
 SPINNER
*/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner,
.spinner-primary {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: spinner 1.2s linear infinite;
}

.slide-out{
    animation: slideOut 0.4s ease-in-out;
}

.transition-delay{
  transition: all 1s ease;
}

.dpu-animation{
  transition:all 0.5s ease-in;
}

.card-collapse-transition{
  transition: all 0.3s ease;

}

.card-open-transition {
  transition: all 1s ease;

}

.spinner-primary:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid #0a8bfe;
  border-color: #0a8bfe transparent #0a8bfe transparent;
  animation: spinner 1.2s linear infinite;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
}

.country-select-button,
.unselected-select-button {
  border: 0px !important;
}

.country-select {
  border: 1px solid #e5e7eb !important;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  color: "rgba(100, 102, 114, 0.9)" !important;
  line-height: 24px;
  border-radius: 8px !important;
  margin-bottom: 14px;
}

.unselected-select-button {
  color: "rgba(100, 102, 114, 0.9)" !important;
}

/************************* Rich text overrides ************************/

.rich-text-toolbar-icon,
.rdw-dropdown-wrapper,
.rdw-option-wrapper,
.rdw-image-wrapper {
  border: none !important;
  background: none !important;
  margin-right: 10px !important;
}

.rich-text-toolbar-icon:hover,
.rdw-dropdown-wrapper:hover,
.rdw-option-wrapper:hover,
.rdw-image-wrapper:hover,
.rdw-option-active {
  box-shadow: none !important;
  /* border-bottom: 1px solid #0e1227 !important; */
}

.rich-text-toolbar-icon:hover,
.rdw-option-active {
  border-bottom: 1px solid #0e1227 !important;
}

.rich-text-toolbar {
  border: none !important;
  border-bottom: 1px solid #e5e7eb !important;
  padding: 16px !important;
  background: rgba(229, 231, 235, 0.2) !important;
  position: relative !important;
  margin-bottom: 0px !important;
}

.inline-section,
.rdw-dropdown-wrapper,
.rdw-list-wrapper {
  border-right: 0.5px solid #e5e7eb !important;
}

.rdw-dropdown-wrapper {
  color: #646672;
  padding: 0px 12px;
}

.rdw-dropdown-carettoopen {
  border-top: 6px solid #646672 !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.rdw-text-align-dropdown .rdw-dropdown-carettoopen {
  display: none;
}

.rdw-link-wrapper,
.rdw-image-wrapper {
  margin-right: 0px !important;
}

.rdw-emoji-wrapper {
  margin-left: 10px !important;
}

.public-DraftStyleDefault-block {
  margin: 4px 0 !important;
}

.public-DraftStyleDefault-ltr span {
  color: #646672;
  word-break: break-word;
}

.flip-wrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
  min-height: auto !important;
}

h1 .public-DraftStyleDefault-ltr span,
h2 .public-DraftStyleDefault-ltr span,
h3 .public-DraftStyleDefault-ltr span,
h4 .public-DraftStyleDefault-ltr span {
  color: #0e1227 !important;
  font-family: "Inter-Medium";
}

.rdw-text-align-dropdown.rdw-dropdown-wrapper {
  padding-left: 0px;
  display: flex;
  align-items: center;
  margin-top: -4px;
}
/* React tag input start */
.react-tagsinput:first-child {
  display: flex !important;
  flex-wrap: wrap !important;
}

.react-tagsinput {
  border-radius: 8px;
  border-color: #e5e7eb !important;
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}

.react-tagsinput--focused {
  outline: none;
  border-color: #e5e7eb !important;
}

.react-tagsinput-tag {
  font-family: "Inter-Medium" !important;
  font-size: 12px !important;
  padding: 0px 12px 0px 6px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}

.react-tagsinput-tag a::before {
  font-size: 16px !important;
  cursor: pointer !important;
  color: "rgba(100, 102, 114, 0.9)" !important;
}

.react-tagsinput-input {
  font-family: "Inter-Medium" !important;
  font-size: 12px !important;
  line-height: 24px !important;
  width: 100% !important;
  color: #0e1227 !important;
  margin-bottom: 0px !important;
}

/* React tag input end */

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.circle-wrap {
  margin: 150px auto;
  width: 150px;
  height: 150px;
  background: #fefcff;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #2196f3;
}

.textarea-scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.textarea-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px #646672;
}

.textarea-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #0e1227 !important;
  --toastify-color-info: #0a8bfe !important;
  --toastify-color-success: #4f9c20 !important;
  --toastify-color-warning: #646672 !important;
  --toastify-color-error: #ec5962 !important;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 360px !important;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 80px !important;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Inter-Regular" !important;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #0e1227 !important;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #f9829b !important;
  --toastify-spinner-color-empty-area: #1188fe !important;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #f9829b,
    #5ac8fa,
    #1188fe,
    #34aadc,
    #5856d6,
    #f9829b
  ) !important;
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.hide-scrollbar {
  scrollbar-width: none; /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.Typewriter__cursor {
  color: #2196f3;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 20vh;
  background: rgba(229, 231, 235, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(100, 102, 114, 0.3);
  border-radius: 20vh;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a6b7ed;
}

@keyframes highlightEffect {
  from {
    background-color: yellow;
  }
  to {
    background-color: transparent;
  }
}

.css-1aarqpz-control {
  min-width: 70px !important;
  display: flex !important;
}

.react-joyride__overlay {
  z-index: 90;
}

.loader {
  border: 5px solid #fff;
  border-bottom-color: #0e1227;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.widget-paraphrase {
  transition: width 0.5s ease-in;
}

.large-text {
  font-size: 32px;
}

.review-carousel img {
  width: auto !important;
}

.carousel-slider {
  display: flex !important;
  margin-top: 12px;
  flex-direction: column-reverse;
}

.review-carousel .control-dots {
  width: 75px !important;
  position: relative !important;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 800px) {
  .dashboard-top-grid {
    display: grid;
    grid-template-columns: 300px 3fr 2fr;
  }
}

.grid-cols-layout{
  grid-template-columns: 312px 1fr;
}

.page{
  padding:20px;
  background-color: white;
}


[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: 0.6;
}

.brand-gradient-text {
  background: linear-gradient(20deg, #b74bdd 40%, #0379ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.brand-gradient-text-glow {
  background: linear-gradient(107.79deg, #FF805F 3.76%, #B74BDD 51.88%, #0379FF 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.brand-gradient-2 {
  background: linear-gradient(107.79deg, #FF805F 3.76%, #B74BDD 51.88%, #0379FF 100%);
}


.sidebar-shadow{
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.layout-content-width-desktop{
  width:calc(100vw - 312px);
  margin-left:312px;
}

.layout-content-width-full{
  width:100vw;
}

.group-container {
  padding-top: 2px !important;
}

.group-container div:nth-child(2) div {
  padding-left: 30px;
  border-radius: 0px;
}

.group-container div:nth-child(2) div:hover {
  border-radius: 0px !important;
  background-color: #F0E9FE;
}


.ce-inline-toolbar{
  width:100%;
  margin-left:400px;
  height: 40px;
  background-color: white;
  position:fixed;
  top: 96vh !important;
  display: block;
  left:340px;
  visibility: visible;
  margin:auto;
  display: flex;
  justify-content: center;
  box-shadow:none;
  border:none;
  background-color:#F0E9FE;
}


.spinner-circle:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  /* top: 50%;
  left: 50%; */
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-left: -9px;
  border-radius: 50%;
  border: 2px solid #fbfcfe;
  border-top-color: #0a8bfe;
  animation: rotate 0.6s linear infinite;
}

.spinner-circle_lg:before {
  width: 24px;
  height: 24px;
  margin-top: -14px;
  margin-left: -12px;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
